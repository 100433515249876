import { computed, observable } from "mobx";
import { wordPressService } from "../../../../cdm/services/wordpress-service";
import { mcbEndpointConfig } from "../../config/api-config";
import { stateController } from "../../../../cdm/controllers/state-controller";
import { clientController } from "../../../../cdm/controllers/client-controller";

export class GroupFinderPageController {
  @observable finderUrl = "";

  @computed get group() {
    return clientController.findGroupById(stateController.viewGroupId);
  }

  @computed get isScratchpad() {
    return (
      !!this.group.groupName && this.group.groupName.match(/{scratchpad}/g)
    );
  }

  constructor(props) {
    this.props = props;
    this.loadFinder();
  }

  loadFinder = () => {
    wordPressService.setLoading(true);
    wordPressService.setWpHostname(mcbEndpointConfig.finder_hostname);
    this.finderUrl = `${window.location.protocol}//${
      mcbEndpointConfig.finder_hostname
    }/standalone?cafccid=${stateController.viewGroupId}${
      this.isScratchpad ? "" : `&hideGroupSelector=true`
    }`;
  };
}

import { env } from "../../../config/variable-config";

export const mcbEndpointConfig = {
  // Marketplace
  search_marketplace: `/marketplace/searchCaregivers`,
  invite_hire: groupId => `/member/groupId/${groupId}/hire`,
  admin_prep_broadcast: "/topic/broadcast",
  admin_send_broadcast: "/messaging/broadcast",
  admin_gain_loss_access: "/adminCentre/gainLossAccessToGroup",

  // Shift Maker
  shift_template_active: (groupId, topicId, memberId, action) =>
    `/topic/activatedStatus/topicId/${topicId}/groupId/${groupId}/memberId/${memberId}?action=${action ||
      ""}`,
  clock_in_out_shift: (groupId, topicId, memberId, action, time) =>
    `/topic/topicId/${topicId}/groupId/${groupId}/memberId/${memberId}/clock?action=${action ||
      ""}&time=${time || ""}`,
  // send_shift_notification: (groupId, topicId) =>
  //   `/topic/topic/sendEmailToShiftAssignees/groupId/${groupId}/topicId/${topicId}`,
  send_shift_notification_assigned: "/topic/sendEmailToShiftAssignees",
  send_shift_notification_unassigned: "/topic/sendEmailToShiftRemoved",

  // Report
  get_dashboard_summary: groupId =>
    `/report/dashboardSummary/groupId/${groupId}`,
  get_completed_shift_summary: "/report/payrollSummary",
  shift_summary_approval_disapproval: "/email/approveAndDisapprovePayroll",

  //Timesheets
  get_timesheets_for_care_circle: groupId =>
    `/topicExt/timesheetsforCareCircle/${groupId}`,

  // Admin
  get_all_admin_centre_groups: `/adminCentre/allNewGroups`,
  get_all_admin_centre_groups_paged: (sortBy, lastId, size, reverse) =>
    `/profile/pagination/allNewGroups?sortBy=${sortBy}&offset=${lastId}&pageSize=${size}&order=${
      reverse ? "desc" : "asc"
    }`,
  notify_care_circle_creation: "/member/emailForCareCircle",
  get_all_promos: "/promo",
  modify_screening_status: "/topic/modifyScreeningStatus",
  convert_household_to_care_circle: `/groupExt/convertHouseholdToCareCircle`,

  // Issue
  send_issue_notification: (groupId, topicId) =>
    `/topic/sendEmailToIssueReceivers/groupId/${groupId}/topicId/${topicId}`,
  check_notify_issue_completion: topicId =>
    `/topicExt/checkIssueResolution/${topicId}`,

  // Proactive chat
  send_proactive_chat_status: `/topic/proactiveChatStatus`,
  send_new_visitor_proactive_chat_sse: (topicId, isDevUser) =>
    `/topic/sendNewVisitorSSE/topicId/${topicId}?isDevUser=${
      isDevUser ? 1 : 0
    }`,

  // Caregiver Onboard
  caregiver_onboard: `/registration/signup/caregiver`,
  caregiver_onboarding: "/onboarding",
  // caregiver_onboard: `http://localhost:8081/registration/signup/caregiver`,
  caregiver_onboard_info_req_hash: `/registration/signup/caregiver/information_request/verify`,
  caregiver_onboard_info_req_submit: `/registration/signup/caregiver/information_request/submit`,
  caregiver_onboard_complete_profile: `/registration/signup/caregiver/complete_profile/verify`,
  caregiver_onboard_resend_info_request: profileId =>
    `/topic/sendInfoRequestEmail/profileId/${profileId}`,
  caregiver_onboard_resend_profile_complete: profileId =>
    `/topic/sendProfileCompleteEmail/profileId/${profileId}`,
  caregiver_onboard_resend_profile_complete_old: profileId =>
    `/topic/sendProfileCompleteEmailOld/profileId/${profileId}`,
  get_caregiver_new_onboarding_by_profile_id: profileId =>
    `/onboarding/profileId/${profileId}`,
  caregiver_new_onboarding_modify_screening_status: id =>
    `/onboarding/${id}/screeningStatus`,
  caregiver_job_reference_response_questions: `/jobReferenceResponse/questions`,
  get_questionnaire_text_questions: "onboarding/questions",
  caregiver_job_reference_response: (
    id,
    onboardingId,
    emailQuestionResponseId
  ) =>
    `/jobReferenceResponse/${id}/onboardingId/${onboardingId}/responseId/${emailQuestionResponseId}`,
  caregiver_job_reference_candidate_profile: id =>
    `/jobReferenceResponse/${id}/candidateProfile`,
  submit_caregiver_job_reference_response: `/jobReferenceResponse/submit`,
  update_profile_with_audit: id => `/groupExt/profile/withAudit/${id}`,
  update_profile_with_reminders: id =>
    `/groupExt/profile/updateWithReminders/${id}`,

  caregiver_upload_validate_data: `/onboardingUpload/validate`,
  caregiver_upload: "/onboardingUpload/upload",
  caregiver_onboarding_upload_data_by_onboarding_id: (
    onboardingId,
    populateFileList
  ) =>
    `/onboardingUpload/onboardingId/${onboardingId}?populateFileList=${
      populateFileList ? 1 : 0
    }`,
  caregiver_onboard_resend_questionnaire: profileId =>
    `/onboarding/profileId/${profileId}/resendQuestionnaireEmail`,
  get_caregiver_achievement: groupId => `/achievement/${groupId}`,
  batch_get_caregiver_achievement: "/achievement",

  // Service provider register
  provider_signup: `/registration/signup/provider`,
  provider_signup_no_vet: `/registration/signup/provider/no_vet`,

  // Wrapper endpoints
  get_wrapper_fields: "/formExt/public/form/wrapperFields",
  get_wrapper_caregiver_fields: "/formExt/public/form/wrapperCaregiverFields",
  get_service_group_types: "/formExt/public/serviceGroupTypes",

  // CAF 2.0
  get finder_hostname() {
    return window.location.hostname === "localhost"
      ? "localhost:5000"
      : env === "devtest"
      ? "mycarebase-finder-zr5z3vsejq-uw.a.run.app"
      : env === "test"
      ? "mycarebase-finder-zcognvxuhq-uw.a.run.app"
      : "app.mycarebase.com/finder";
  }
};

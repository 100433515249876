import { Platform } from 'react-native';

export const nativeEnv = "devtest"; // Please manually change before building native packages.
export const env =
  Platform.OS === "web"
    ? window.location && window.location.port.match(/10000|10001/g)
      ? "dev"
      : (window.location && window.location.port.match(/3000|3001/g)) ||
        window.location.hostname.match(/^devapp|devr|devlocal/g)
      ? "devtest"
      : window.location && window.location.hostname.match(/^app/g)
      ? "prod"
      : "test"
    : nativeEnv;

export const profilePrototype = {
  typeClassId: 0,
  typeClassVersion: 1,
  data: {}
};

export const topicPrototype = {
  onCalendar: 0,
  isTemplate: 0,
  isParentTemplate: 0,
  isCompleted: 0,
  isDataLocked: 0,
  isLocked: 0,
  data: JSON.stringify({}),
  typeClassVersion: 1 // Default for now.
};

export const userStatus = {
  archived: 0,
  normal: 1,
  unverified: 2
};

export const memberStatus = {
  archived: 0,
  normal: 1,
  unverified: 2,
  invited: 3
};

export const invitationStatus = {
  new: 0,
  pendingAccept: 1,
  completed: 2,
  cancelled: 3
};

export const groupTypeIds = {
  caregiver: 3,
  careReceiver: 2,
  household: 7,
  cleaner: 8,
  handyman: 9,
  transportation: 10,
  myCareBaseStaff: 5
};

export const topicTypeIds = {
  shortlist: 8,
  candidate: 9,
  groupMessaging: 7,
  messaging: 10,

  timesheet: 11,
  shift: 2,
  task: 3,
  note: 4,
  issue: 5,

  mCBBroadcasts: 12,
  proactiveChat: 13,

  onboarding: 14,
  lead: 15,

  invoice: 16,

  groupChatLobby: 17
};

export const typeClassIds = {
  personalProfile: 0,
  // careReceiverProfile: 2,
  // careReceiverProfile: 7, // Updated 2019-05-21
  careReceiverProfile: 9, // Updated 2019-06-06
  // caregiverProfile: 1,
  // caregiverProfile: 6, // Updated 2019-05-17
  caregiverProfile: 10, // Updated 2019-06-06
  familyMemberProfile: 3, // Generic for accepting invitation, temporary.
  paidCaregiverProfile: 29,

  shortlistTopic: 4,
  // candidateTopic: 5,
  messagingTopic: 8,
  candidateTopic: 11, // Updated 2019-06-06
  issueTopic: 30,

  shiftTemplateTopic: 28
};

export const groupTypeRoleIds = {
  primaryFamilyCaregiver: 3,
  secondaryFamilyCaregiver: 4,
  careReceiver: 5,
  paidCaregiver: 6,
  healthProvider: 7,
  friend: 8,
  family: 11,

  caregiver: 9,

  primaryMember: 17,
  otherMember: 18,

  cleaner: 19,
  handyman: 20,
  transportation: 21,

  careConcierge: 22,
  support: 23,
  get techSupport() {
    return groupTypeRoleIds.support;
  },

  mCBStaff: 16
};

export const groupTypeActorIds = {
  [groupTypeIds.careReceiver]: {
    owner: 2,
    assigned: 3,
    participants: 4,
    observers: 5
  },
  [groupTypeIds.myCareBaseStaff]: {
    owner: 7,
    assigned: 8,
    participants: 9,
    observers: 10
  },
  [groupTypeIds.caregiver]: {
    owner: 6
  }
};

export const RoleGroupGroupTypeIds = [
  groupTypeIds.caregiver,
  groupTypeIds.cleaner,
  groupTypeIds.handyman,
  groupTypeIds.transportation
];

export const serviceRecipientGroupTypeIds = [
  groupTypeIds.careReceiver,
  groupTypeIds.household
];

export const serviceProviderGroupTypeIds = [
  groupTypeIds.handyman,
  groupTypeIds.cleaner,
  groupTypeIds.transportation
];

export const billingEnabledGroupTypeIds = [
  groupTypeIds.careReceiver,
  groupTypeIds.household,
  groupTypeIds.caregiver
  // More to come
];

export const rnrEnabledRoleGroupTypeIds = [
  groupTypeIds.caregiver,
  groupTypeIds.cleaner
];

export const groupMemberWording = {
  singular: "a Member",
  plural: "Members"
};

export const consoleEnabledUsers = [
  "felix",
  "liang",
  "liangli",
  "leonli",
  "miku",
  "heller",
  "happy",
  "happy1",
  "happy8"
];

export const credPersistUsers = [
  "happy",
  "happy1",
  "liangli",
  "felix",
  "leonli",
  // Stephanie
  "lucy"
];

export const avatarSizeLimit = 8192; // KB
export const fileSizeLimit = 8192; // KB

export const placeholderPickerValue = -2147483648;

export const planMarketingUrl =
  "https://www.mycarebase.com/care-management-tools-pricing";

export const planMarketingNumber = "1-877-822-7464";

export const planMarketingContactUrl = "https://www.mycarebase.com/contact-us";

export const caregiverRateMarkupFactor = 1.25;

export const termsUrl = "https://app.mycarebase.com?showTerms=true";

export const botUserDefaultId = -2;

export const backCheckUrl =
  "https://pages.sterlingbackcheck.ca/landing-pages/m/mycarebase/";

export const calendlyFrameEvents = [
  "calendly.profile_page_viewed",
  "calendly.event_type_viewed",
  "calendly.date_and_time_selected",
  "calendly.event_scheduled"
];

//WP variables
export const wpPaymentMethodUri = "/my-account/payment-methods/";

export const wpSubscriptionUri = "/my-account/subscriptions/";

export const wpNewSubscriptionUri = "/shop-internal";

export const wpShopGroupTypeUrls = {
  [groupTypeIds.caregiver]: `${wpNewSubscriptionUri}/caregiver-plans`
};

export const wpAddPaymentMethodUri = "/my-account/add-payment-method/";

export const wpCheckoutUrl = "/checkout";

// export const defaultLocalizedTimezoneName = "America/Vancouver";

// https://console.cloud.google.com/apis/credentials/key/227862d7-a049-4f5a-929e-e5cd6415d1de?project=mycarebase-microservices
export const placesApiKey = "AIzaSyAIXP8B-UkyspbeWWuUfVLgSETAxbHb-9I";
// https://console.cloud.google.com/apis/credentials/key/38cd7c8f-fa31-4be9-8a59-06187482963f?authuser=1&project=mycarebase-web
export const devPlacesApiKey = "AIzaSyCNYOKwwlC-swIFJK8mu7RrbKBopGOc0Y8";
export const placesBaseUrl = "https://maps.googleapis.com/maps/api/js";

export const achievementColors = {
  bronze: "#CD7F32",
  silver: "#C0C0C0",
  gold: "#FFD700"
};
